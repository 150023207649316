import { authOptions } from 'pages/api/auth/[...nextauth]';
import { getServerSession as nextGetServerSession } from 'next-auth/next';
import { axios, setAuthorization } from 'utils/axios';
import redirectChecker from 'utils/serverProps/redirectChecker';

export const redirectTo = (url) => {
  return {
    redirect: {
      destination: url,
      permanent: false,
    },
  };
};

export const getServerSession = async (context) => {
  const session = await nextGetServerSession(context.req, context.res, authOptions);
  if (session?.user) {
    setAuthorization(session.user.key);
  }
  return session;
}

export async function getSessionAndUser(context) {
  let user = null;
  const session = await getServerSession(context);

  if (session?.user) {
    try {
      const response = await axios.get('/user/me/');
      user = { ...response.data, key: session.user.key };
    } catch (error) {
      return { session, user: null };
    }
  }

  return { session, user };
}

export async function getServerSideProps(context) {

  const { session, user } = await getSessionAndUser(context);

  if (session) {
    if (session.error) return redirectTo(`/logout?msg=${session.error}`);
    if (!user) return redirectTo(`/logout?msg=Your session has expired`);

    const redirectUrl = await redirectChecker(context, user);
    if (redirectUrl) return redirectTo(redirectUrl);
  }

  return {
    props: {
      session,
      user,
    },
  };
}
