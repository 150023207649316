export const GoogleProvider = (id) => {
  return {
    id: id || 'google',
    name: 'Google',
    type: 'oauth',
    wellKnown: 'https://accounts.google.com/.well-known/openid-configuration',
    authorization: {
      params: {
        scope: 'openid email profile',
      },
    },
    issuer: 'https://accounts.google.com',
    idToken: true,
    checks: ['pkce', 'state'],

    profile(profile) {
      return {
        id: profile.sub,
        name: profile.name,
        email: profile.email,
        image: profile.picture,
      };
    },
    style: {
      logo: '/google.svg',
      logoDark: '/google.svg',
      bgDark: '#fff',
      bg: '#fff',
      text: '#000',
      textDark: '#000',
    },
    clientId: process.env.GOOGLE_CLIENT_ID,
    clientSecret: process.env.GOOGLE_CLIENT_SECRET,
  };
};

export const LinkedInProvider = (id) => {
  return {
    id: id || 'linkedin',
    name: 'LinkedIn',
    type: 'oauth',
    authorization: {
      url: 'https://www.linkedin.com/oauth/v2/authorization',
      params: {
        scope: 'r_liteprofile r_emailaddress',
      },
    },
    token: 'https://www.linkedin.com/oauth/v2/accessToken',
    client: {
      token_endpoint_auth_method: 'client_secret_post',
    },
    userinfo: {
      url: 'https://api.linkedin.com/v2/me',
      params: {
        projection: `(id,localizedFirstName,localizedLastName,profilePicture(displayImage~digitalmediaAsset:playableStreams))`,
      },
    },

    async profile(profile, tokens) {
      var _emailData$elements,
        _emailData$elements$,
        _emailData$elements$$,
        _profile$profilePictu,
        _profile$profilePictu2,
        _profile$profilePictu3,
        _profile$profilePictu4,
        _profile$profilePictu5,
        _profile$profilePictu6;

      const emailResponse = await fetch(
        'https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))',
        {
          headers: {
            Authorization: `Bearer ${tokens.access_token}`,
          },
        },
      );
      const emailData = await emailResponse.json();
      return {
        id: profile.id,
        name: `${profile.localizedFirstName} ${profile.localizedLastName}`,
        email:
          emailData === null || emailData === void 0
            ? void 0
            : (_emailData$elements = emailData.elements) === null ||
              _emailData$elements === void 0
            ? void 0
            : (_emailData$elements$ = _emailData$elements[0]) === null ||
              _emailData$elements$ === void 0
            ? void 0
            : (_emailData$elements$$ = _emailData$elements$['handle~']) ===
                null || _emailData$elements$$ === void 0
            ? void 0
            : _emailData$elements$$.emailAddress,
        image:
          (_profile$profilePictu = profile.profilePicture) === null ||
          _profile$profilePictu === void 0
            ? void 0
            : (_profile$profilePictu2 =
                _profile$profilePictu['displayImage~']) === null ||
              _profile$profilePictu2 === void 0
            ? void 0
            : (_profile$profilePictu3 = _profile$profilePictu2.elements) ===
                null || _profile$profilePictu3 === void 0
            ? void 0
            : (_profile$profilePictu4 = _profile$profilePictu3[0]) === null ||
              _profile$profilePictu4 === void 0
            ? void 0
            : (_profile$profilePictu5 = _profile$profilePictu4.identifiers) ===
                null || _profile$profilePictu5 === void 0
            ? void 0
            : (_profile$profilePictu6 = _profile$profilePictu5[0]) === null ||
              _profile$profilePictu6 === void 0
            ? void 0
            : _profile$profilePictu6.identifier,
      };
    },

    style: {
      logo: '/linkedin.svg',
      logoDark: '/linkedin-dark.svg',
      bg: '#fff',
      text: '#069',
      bgDark: '#069',
      textDark: '#fff',
    },
    clientId: process.env.LINKEDIN_CLIENT_ID,
    clientSecret: process.env.LINKEDIN_CLIENT_SECRET,
  };
};
