import React from 'react';
import Box from '@mui/material/Box';
import Head from 'next/head';
import { PageLoading } from 'components/PageLoading';

import { Topbar, Footer } from './components';
import useLayoutStore from 'stores/layoutStore';

/**
 *
 * @param {*} children - page content.
 * @param {*} hideTopbar - If true, the topbar will be hidden.
 * @param {*} hideActions - If true, all actions will be hidden.
 * @param {*} showSignup - If true, the signup button will be shown.
 * @param {*} title - page title.
 * @returns
 */
const Main = ({
  children,
  hideTopbar = false,
  hideActions = false,
  showSignup = false,
  title = 'Rejigg',
}) => {
  const pageLoadingMessage = useLayoutStore((state) => state.pageLoadingMessage);

  if (pageLoadingMessage) {
    return <PageLoading title={pageLoadingMessage} />;
  }

  return (
    <>
      <Head>{title && <title>{title}</title>}</Head>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        {!hideTopbar && (
          <Topbar showSignup={showSignup} hideActions={hideActions} />
        )}

        <Box
          component={'main'}
          backgroundColor={'alternate.main'}
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </Box>

        <Footer />
      </Box>
    </>
  );
};

export default Main;
