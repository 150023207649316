import NextAuth from 'next-auth';
import CredentialsProvider from 'next-auth/providers/credentials';
import * as backendApi from 'lib/backendApi';
import { NEXT_AUTH_DEBUG } from 'utils/constants';
import { GoogleProvider, LinkedInProvider } from 'utils/signupProviders';

const isDev = process.env.NODE_ENV === 'development';
const cookies = {
  sessionToken: {
    name: `__Secure-next-auth.session-token`,
    options: {
      path: '/',
      httpOnly: true,
      secure: true,
    },
  },
  callbackUrl: {
    name: `__Secure-next-auth.callback-url`,
    options: {
      path: '/',
      sameSite: 'none',
      secure: true,
    },
  },
  csrfToken: {
    name: `__Host-next-auth.csrf-token`,
    options: {
      path: '/',
      httpOnly: true,
      secure: true,
    },
  },
};

export const authOptions = {
  ...(isDev ? {} : { cookies }),
  providers: [
    CredentialsProvider({
      id: 'credentials',
      async authorize(credentials, req) {
        // Any exception in this function continues with the signIn function in the frontend
        const response = await backendApi.loginOrRegisterUser(
          'credentials',
          credentials,
        );
        if (credentials.action === 'login') {
          const user = response.user;
          user['key'] = response.key;
          return user;
        }
        return response; // this continues with the authOptions.callbacks flow
      },
    }),
    GoogleProvider('google-signup'),
    GoogleProvider('google'),
    LinkedInProvider('linkedin-signup'),
    LinkedInProvider('linkedin'),
  ],
  session: {
    strategy: 'jwt',
  },
  pages: {
    signIn: '/account/login',
    error: '/account/login',
  },
  debug: NEXT_AUTH_DEBUG === 'true',
  callbacks: {
    /**
     * Documentation: https://next-auth.js.org/configuration/callbacks
     */
    async signIn(context) {
      /**
       * Credentials context: user, account, credentials
       * Google context: user, account, profile
       * Linkedin context:  user, account, profile
       */
      if (NEXT_AUTH_DEBUG === 'true') {
        console.log('==========<next-auth callback: signIn>==============');
        console.log({ context });
        console.log('==========</next-auth callback: signIn>==============');
      }

      let userObj = null;
      if (context.account.provider.startsWith('google')) {
        userObj = {
          username: context.profile.email,
          first_name: context.profile.given_name,
          last_name: context.profile.family_name,
          email: context.user.email,
          email_verified: context.profile.email_verified,
          image: context.profile.picture,
        };
      } else if (context.account.provider.startsWith('linkedin')) {
        userObj = {
          username: context.user.email,
          first_name: context.profile.localizedFirstName,
          last_name: context.profile.localizedLastName,
          email: context.user.email,
          email_verified: true,
          image: context.user.image,
        };
      }

      let action = 'login';
      let errorPage = '/account/login';
      if (
        context.account.provider.includes('-signup') ||
        context?.credentials?.action === 'signup'
      ) {
        action = 'signup';
        errorPage = '/account/signup';
      }

      if (userObj) {
        /**
         * if userObj is defined, then it is a social login/signup
         */
        userObj['action'] = action;
        userObj['provider_data'] = context;
        try {
          const response = await backendApi.loginOrRegisterUser(
            context.account.provider,
            userObj,
          );

          context.user.key = response.key;
          context.user.id = response.user.id;
        } catch (error) {
          console.error(`${action} error: ${error}`);
          return `${errorPage}?error=${error.message}`;
        }
      }

      if (context.user?.detail) {
        return `${process.env.NEXTAUTH_URL}${errorPage}?message=${context.user?.detail}`;
      }
      if (context.user) return true;
      return false;
    },
    async jwt(context) {
      /**
       * Credentials context:  token, user, account, isNewUser
       * Google context: token, user, account, profile, isNewUser
       * Linkedin context: token, user, account, profile, isNewUser
       */
      if (NEXT_AUTH_DEBUG === 'true') {
        console.log('==========<next-auth callback: jwt>==============');
        console.log({ context });
        console.log('==========</next-auth callback: jwt>==============');
      }
      if (context.user) {
        context.token = {
          ...context.token,
          ...context.user,
        };
      }
      return context.token;
    },
    async session(context) {
      /**
       * Credentials context: session, token
       * Google context: session, token
       * Linkedin context: session, token
       */
      if (NEXT_AUTH_DEBUG === 'true') {
        console.log('==========<next-auth callback: session>==============');
        console.log({ context });
        console.log('==========</next-auth callback: session>==============');
      }
      context.session.user = context.token;
      context.session.user.isAuthenticated = true;

      return context.session;
    },
  },
  secret: process.env.NEXTAUTH_SECRET || 'looselipssinkships',
};

export default NextAuth(authOptions);
