import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { MainLogo } from 'components/MainLogo';
import useUserStore from 'stores/userStore';
import { useTheme } from '@mui/material/styles';
import { Link } from '@mui/material';

const Topbar = ({ showSignup = false, hideActions = false }) => {
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);

  const theme = useTheme();

  return (
    <Box
      py={3}
      px={2}
      sx={{
        borderBottom: 'solid thin #ddd',
        position: 'sticky',
        top: 0,
        width: '100%',
        zIndex: 5000,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box display={'flex'} title="rejigg" width={{ xs: 100, md: 120 }}>
          <MainLogo invert={theme.palette.mode == 'dark'} href="/" />
        </Box>

        {!hideActions && (
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <Box>
              <Button
                color="primary"
                variant="outlined"
                component={Link}
                href="/account/login"
              >
                Log in
              </Button>
            </Box>

            <Box sx={{ ml: 2 }}>
              {showSignup && !isAuthenticated && (
                <Button
                  color="primary"
                  variant="outlined"
                  component={Link}
                  href="/account/signup"
                >
                  Sign up
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
