import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();

  return (
    <Container>
      <Grid container spacing={2} py={3}>
        <Grid item xs={12}>
          <Typography
            align={'center'}
            variant={'subtitle2'}
            color="text.secondary"
            gutterBottom
          >
            &copy; Rejigg 2024. All rights reserved
          </Typography>

          <Typography
            align={'center'}
            variant={'caption'}
            color="text.secondary"
            component={'p'}
          >
            When you visit or interact with our sites, services or tools, we or
            our authorised service providers may use cookies for storing
            information to help provide you with a better, faster and safer
            experience and for marketing purposes.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
