/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  GoogleLoginButton,
  LinkedInLoginButton,
} from 'react-social-login-buttons';
import { Alert, Divider } from '@mui/material';
import { signIn } from 'next-auth/react';
import { CircularProgress } from '@mui/material';
import { MIN_PASSWORD_LENGTH } from 'utils/constants';
import { useRouter } from 'next/router';
import { resendVerificationEmail } from 'lib/backendApi';
import useLayoutStore from 'stores/layoutStore';
import { dayjs } from 'utils/dayjs';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(
      MIN_PASSWORD_LENGTH,
      `The password should have at minimum length of ${MIN_PASSWORD_LENGTH}`,
    ),
});

const LoginForm = ({ appViewDefault = null }) => {
  const router = useRouter();
  const setPageLoadingMessage = useLayoutStore(
    (state) => state.setPageLoadingMessage,
  );
  const { error } = router.query; // TODO: https://logfetch.com/next-auth-custom-error-page/
  const [appView, setAppView] = useState(appViewDefault || '');
  const [errorCode, setErrorCode] = useState(null);
  const [alertType, setAlertType] = useState('info');
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    timezone: dayjs.tz.guess(),
  });

  const providerLogin = async (provider) => {
    const urlParams = {
      appView: appViewDefault,
      timezone: dayjs.tz.guess(),
    };
    if (router.query.callbackUrl) {
      urlParams.callbackUrl = router.query.callbackUrl;
    }
    const params = new URLSearchParams(urlParams).toString();
    await signIn(provider, { callbackUrl: `/update-settings?${params}` });
  };

  const showErrorFromObject = (errorObj, formikSetStatus, formikSetErrors) => {
    if (Object.keys(errorObj).includes('non_field_errors')) {
      setAlertType('error');
      formikSetStatus(errorObj['non_field_errors']);
      return;
    }
    setErrorCode(errorObj?.error_code);
    formikSetErrors(errorObj);
    formikSetStatus(errorObj?.email);
    setAlertType('warning');
  };

  const onSubmit = async (values, { setStatus, setErrors }) => {
    cleanUp();

    const response = await signIn('credentials', {
      action: 'login',
      email: values.email.toLowerCase(),
      username: values.email.toLowerCase(),
      password: values.password,
      timezone: dayjs.tz.guess(),
      app_view: appView,
      redirect: false,
    });

    if (!response.ok) {
      const errorObj = JSON.parse(response.error);
      showErrorFromObject(errorObj, setStatus, setErrors);
    } else {
      setPageLoadingMessage('Logging in...');
      router.reload().then(() => setPageLoadingMessage(''));
    }
    return response;
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const cleanUp = () => {
    setErrorCode(null);
    formik.setErrors({});
    formik.setStatus(null);
  };

  const handleResendVerificationEmail = async (e) => {
    e.preventDefault();
    if (!formik.values.email) {
      formik.setErrors({ email: 'Email is required.' });
      return;
    }
    cleanUp();
    resendVerificationEmail(formik.values.email)
      .then((resp) => {
        setErrorCode(null);
        setAlertType('success');
        formik.setStatus(
          `Verification Email sent to ${formik.values.email}.\n\nMake sure to check your spam folder.`,
        );
      })
      .catch((err) => {
        console.error(err);
        setErrorCode(err?.error_code);
      });
  };

  useEffect(() => {
    let errorObj;

    if (error) {
      try {
        errorObj = JSON.parse(error);
      } catch {
        errorObj = {};
      }

      setInitialValues({
        ...initialValues,
        email: errorObj?.value,
        timezone: dayjs.tz.guess(),
      });
      showErrorFromObject(errorObj, formik.setStatus, formik.setErrors);
      router.replace(router.pathname, undefined, { shallow: true });
    }
  }, [formik.values]);

  return (
    <Box>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={6}>
          <GoogleLoginButton
            onClick={() => providerLogin('google')}
            style={{ boxShadow: 'rgb(163 163 163 / 50%) 0px 0px 2px 1px' }}
            text={'Google'}
          />
        </Grid>

        <Grid item xs={6}>
          <LinkedInLoginButton
            onClick={() => providerLogin('linkedin')}
            style={{ boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 2px' }}
            text={'LinkedIn'}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} spacing={1}>
        Or
      </Divider>

      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Enter your email
              </Typography>

              <TextField
                autoFocus
                label="Email *"
                variant="outlined"
                name={'email'}
                value={formik.values.email}
                onChange={(e) => {
                  const value = e.target.value || '';
                  formik.setFieldValue('email', value.toLowerCase());
                }}
                type={'email'}
                fullWidth
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
                justifyContent={'space-between'}
                width={1}
                marginBottom={2}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  <Typography variant={'subtitle2'}>
                    Enter your password
                  </Typography>
                </Box>

                <Typography variant={'subtitle2'}>
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'/account/password-reset'}
                    underline={'none'}
                    tabIndex={2}
                  >
                    Forgot your password?
                  </Link>
                </Typography>
              </Box>

              <TextField
                label="Password *"
                variant="outlined"
                name={'password'}
                type={'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                InputProps={{ inputProps: { tabIndex: 0 } }}
                fullWidth
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>

            <Grid item container xs={12}>
              <Box sx={{ width: '100%' }}>
                {formik.status && (
                  <Alert
                    severity={alertType}
                    sx={{ mb: 3, mt: 0, whiteSpace: 'pre-line' }}
                  >
                    {formik.status}
                    <Typography sx={{ mt: 1.5, fontSize: '0.9rem' }}>
                      {errorCode === 'email_not_verified' && (
                        <>
                          <Link
                            variant="text"
                            href="#"
                            onClick={handleResendVerificationEmail}
                          >
                            Click to resend Verification Email
                          </Link>
                          . Make sure to check your spam folder.
                        </>
                      )}
                      {errorCode === 'user_not_found' && (
                        <>
                          Don't have an account?{' '}
                          <Link variant="text" href="/account/signup">
                            Sign up here
                          </Link>
                          .
                        </>
                      )}
                    </Typography>
                  </Alert>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
                justifyContent={'space-between'}
                width={1}
                maxWidth={600}
                margin={'0 auto'}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  {errorCode !== 'user_not_found' && (
                    <Typography
                      sx={{ mt: 1.5, fontSize: 13 }}
                      color={'text.secondary'}
                    >
                      Don't have an account?{' '}
                      <Link href="/account/signup">Sign up here</Link>
                    </Typography>
                  )}
                </Box>

                <Button
                  inputprops={{ tabIndex: 1 }}
                  size={'large'}
                  variant={'contained'}
                  type={'submit'}
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Log in
                  {formik.isSubmitting && (
                    <CircularProgress color="white" size={14} sx={{ ml: 1 }} />
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default LoginForm;
